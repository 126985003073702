import react from "react";
import axios from "axios";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

export async function VerifyUser(username, password) {
  //alert(username + '  and password=' + password);

  return new Promise((resolve, reject) => {
    var bodyFormData = new FormData();
    bodyFormData.append(
      "data",
      JSON.stringify({ username: username, password: password })
    );
    axios({
      method: "post",
      url: "https://extal-api.wdev.co.il/api/admin/Login/login",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (response.data.status == "ok") {
          localStorage.setItem("token", response.data["token"]);

          ///alert("עובדדדד");
          ///console.log(response.data);
          resolve(true);
        }
      })
      .catch((error) => {
        alertify
          .alert("", "Alert Message!", function () {})
          .setContent(
            "<div class='absu'></div><spna class='ErrorMessage'>שם המשתמש והסיסמא אינם תואמים<span>"
          )
          .set("label", "אישור")
          .show();

        //alert("ההתחברות כשלה");
        resolve(false);
      });
  });
}

export async function Verify() {
  let data;
  if (localStorage.getItem("token") == null) {
    return { status: false };
  } else {
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData();
      bodyFormData.append(
        "token",
        JSON.stringify({ token: localStorage.getItem("token") })
      );
      axios({
        method: "post",
        url: "https://extal-api.wdev.co.il/api/admin/Login/verify",
        data: bodyFormData,
        headers: {
          Accept: "application/json",
          Auth: localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          if (response.data.status == true) {
            resolve({ status: true, obj: response.data });
          } else {
            resolve(false);
          }

          console.log(response.data);
        })
        .catch((error) => {
          alert("עמוד זה דורש התחברות , הינך מוחזר לעמוד הבית");
          resolve(false);
        });
    });
  }
}

export default function Req() {
  return <></>;
}
