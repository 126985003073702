import React from "react";
import { useHistory } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.rtl.min.css";
import SwitchToggle from "react-switch";

export default function (props) {
  const history = useHistory();
  const { version, toggle, Settoggle } = props;

  function LogOut() {
    localStorage.setItem("token", null);
    history.push("/login");
  }

  function HomePage() {
    history.push("/");
  }

  if (version === "V1") {
    return (
      <div class="TopSession d-flex justify-content-between bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="p-2 bd-highlight">
          <img onClick={HomePage} className="TopLogoClick" src="logo.png" />
        </div>
        <div class="p-2 bd-highlight">
          <div onClick={LogOut} className="IconBTN">
            <img src="GetOut.svg" />

            <span>התנתקות</span>
          </div>
        </div>
      </div>
    );
  }

  if (version === "V2") {
    return (
      <div class="TopSession d-flex justify-content-between bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="p-2 bd-highlight">
          <img onClick={HomePage} className="TopLogoClick" src="logo.png" />
        </div>
        <div class="p-2 bd-highlight">
          <div onClick={HomePage} className="IconBTN">
            <img src="Home.svg" />

            <span>מסך ראשי</span>
          </div>
        </div>
      </div>
    );
  }

  if (version === "V3") {
    return (
      <div class="TopSession d-flex justify-content-between bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="p-2 bd-highlight">
          <img onClick={HomePage} className="TopLogoClick" src="logo.png" />
        </div>
        <div class="p-2 bd-highlight">
          <div onClick={HomePage} className="IconBTN">
            <img src="homeicone.png" />

            <span>מסך ראשי</span>
          </div>

          <div onClick={HomePage} className="IconBTN">
            <img src="homeicone.png" />

            <span>מסך ראשי</span>
          </div>
        </div>
      </div>
    );
  }

  if (version === "V4") {
    return (
      <div class="TopSession d-flex justify-content-between bd-highlight">
        <div class="p-2 bd-highlight">
          <div className="ToggleBlock">
            <SwitchToggle
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={Settoggle}
              checked={toggle}
              onColor="#28abfa"
            />
            <br />
            <label>הצגת קטלוג</label>
          </div>
        </div>
        <div class="p-2 bd-highlight">
          <img className="LogoClickbl" onClick={HomePage} src="logo.png" />
        </div>
        <div class="p-2 bd-highlight">
          <div onClick={HomePage} className="IconBTN">
            <img src="homeicone.png" />
            <span>מסך ראשי</span>
          </div>
        </div>
      </div>
    );
  }
}
