import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Button, Row, Container, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { tryifhere, Verify, VerifyUser } from "../verifysystem";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

export default function (props) {
  const history = useHistory();

  const [Username, SetUsername] = useState("");
  const [Pass, SetPass] = useState("");

  async function SendToApi() {
    ///alert("sdf");
    if (Username === "" || Pass === "") {
      alertify
        .alert("", "Alert Message!", function () {})
        .setContent(
          "<div class='absu'></div><spna class='ErrorMessage'>יש להקיש שם משתמש וסיסמא<span>"
        )
        .set("label", "אישור")
        .show();

      ////alert("יש למלא שם משתמש וסיסמא!");
      return;
    }

    const verify = await VerifyUser(Username, Pass);
    // alert(verify);
    if (verify === true) {
      history.push("/");
    }
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Row>
        <Col md={4}>
          <div style={{ maxHeight: "99vh" }}>
            <img className="Login-poster" src="sidebar.jpg" />
          </div>
        </Col>
        <Col md={8}>
          <div className="LoginSession">
            <h1 className="LoginH1">ברוכים הבאים לקטלוג אקסטל</h1>
            <div className="Login-Form">
              <div className="Login-Contet">
                <Container>
                  <h2>התחברות</h2>
                  <Form.Control
                    vlaue={Username}
                    onChange={(e) => {
                      SetUsername(e.target.value);
                    }}
                    className="Login-Form-input"
                    type="text"
                    placeholder="אימייל"
                  />
                  <Form.Control
                    value={Pass}
                    onChange={(e) => {
                      SetPass(e.target.value);
                    }}
                    className="Login-Form-input"
                    type="password"
                    placeholder="סיסמא"
                  />
                  <Button onClick={SendToApi} className="LoginBTN">
                    כניסה למערכת
                  </Button>
                </Container>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
