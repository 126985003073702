import "./App.css";
import Login from "./Components/Login";
import CatalogPage from "./Components/CatalogPage";
import { Verify } from "./verifysystem";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AddCatalog from "./Components/AddCatalog";
import AddimagesScreen from "./Components/AddimagesScreen";

function App() {
  const [connected, Setconnected] = useState(false);
  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="Allpages">
        <Router>
          <Switch>
            <Route exact path="/Login">
              <Login />
            </Route>
            <PrivateRoute exact path="/" component={CatalogPage}></PrivateRoute>
            <PrivateRoute
              exact
              path="/AddCatalog"
              component={AddCatalog}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/:AddimagesScreen"
              component={AddimagesScreen}
            ></PrivateRoute>
          </Switch>
        </Router>
      </div>
      <footer>
        <img className="wavelogo" src="result.svg" />
      </footer>
    </div>
  );
}

export default App;
