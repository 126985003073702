import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Button, Row, Container, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { Rnd } from "react-rnd";
import { render } from "react-dom";
import Popupcenter from "../templateparts/PopUpCenter";
import { Sendreq } from "../../req";

export default function (props) {
  const {
    index,
    NumberStart,
    hotspotLinks,
    HotSoptObject,
    removeHotspot,
    Startobject,
    DataBaseSave,
    CubeID,
    uniqID,
  } = props;
  const [LinkAndDescreptions, SetLinkAndDescreptions] = useState(
    HotSoptObject[index]
  );
  const [state, setState] = useState(Startobject);

  const [show, setShow] = useState(false);

  function SaveLinksAndDescreption() {
    setShow(true);
  }
  function SetHotSpotDescription(val) {
    SetLinkAndDescreptions({
      HotSpotDescription: val,
      HotSpotLink: LinkAndDescreptions["HotSpotLink"],
    });
  }

  function SetHotSpotLink(val) {
    SetLinkAndDescreptions({
      HotSpotDescription: LinkAndDescreptions["HotSpotDescription"],
      HotSpotLink: val,
    });
  }

  useEffect(() => {
    console.log("index zaz:" + index);
    console.log(state);
  }, [state]);
  useEffect(() => {
    ///setState(Startobject);
    console.log("bdika object");
    console.log(index);
    console.log("Object Chck");
    console.log(Startobject);
  }, []);

  useEffect(async () => {
    //alert(NumberStart);
    if (NumberStart !== 0) {
      /// alert("מכניס")
      /// x:0, y:0, width:300, height:300
      var element = document.getElementsByClassName(
        "playerProfilePic_home_tile"
      )[0];
      var height = element.offsetHeight;
      var width = element.offsetWidth;

      var BelongCube = CubeID;
      var WidthPercent =
        (parseFloat(ConvertTofloatnumber(state["width"])) / parseFloat(width)) *
        100;
      var HeightPercent =
        (parseFloat(ConvertTofloatnumber(state["height"])) /
          parseFloat(height)) *
        100;
      var LeftPercent =
        (parseFloat(ConvertTofloatnumber(state["x"])) / parseFloat(width)) *
        100;
      var TopPercent =
        (parseFloat(ConvertTofloatnumber(state["y"])) / parseFloat(height)) *
        100;
      var HotSpotLink = LinkAndDescreptions["HotSpotLink"];
      var HotSpotDescription = LinkAndDescreptions["HotSpotDescription"];

      var req = await Sendreq(
        "https://extal-api.wdev.co.il/api/admin/Menagment/AddHotSpots",
        {
          BelongCube: BelongCube,
          WidthPercent: WidthPercent,
          HeightPercent: HeightPercent,
          LeftPercent: LeftPercent,
          TopPercent: TopPercent,
          HotSpotLink: HotSpotLink,
          HotSpotDescription: HotSpotDescription,
          uniqID: uniqID,
        }
      );

      /* 
        console.log("Gova : ");
      
        console.log(height);
      
        console.log("Rohav : ");
        console.log(width);

        console.log("Need to save: " + index);
        console.log(LinkAndDescreptions);
        console.log("Need to save hod: "+index );
        console.log(state); */
    }
  }, [NumberStart]);

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #707070",
    background: "#FFFFFF",
  };
  function ConvertTofloatnumber(str) {
    return str.toString().replace(/[^\d.-]/g, "");
  }

  return (
    <>
      <Popupcenter
        SetHotSpotDescription={SetHotSpotDescription}
        SetHotSpotLink={SetHotSpotLink}
        LinkAndDescreptions={LinkAndDescreptions}
        Version={"V1"}
        setShow={setShow}
        show={show}
      />
      <Rnd
        style={style}
        size={{ width: state.width, height: state.height }}
        position={{ x: state.x, y: state.y }}
        onDragStop={(e, d) => {
          setState({ ...state, x: d.x, y: d.y });
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          setState({
            width: ref.style.width,
            height: ref.style.height,
            x: position.x,
            y: position.y,
          });
        }}
      >
        <img
          className="HotSpotLinks"
          onClick={SaveLinksAndDescreption}
          src="addArrow.png"
        />
        <img
          className="DEL"
          onClick={() => {
            removeHotspot(index);
          }}
          src="delete.png"
        />
      </Rnd>
    </>
  );
}
